#root{
  min-height: 100%;
  height: 100%;
  background-color: #fff;
}
#software-update{
	position: absolute;
	z-index: 5000;
	width: 100%;
	height: 100%;
	top:0;
	left:0;
}